"use client";

import { useTheme } from "next-themes";
import NextImage from "next/image";
import NextLink from "next/link";

import {
  CaretDownIcon,
  HamburgerMenuIcon,
  MoonIcon,
  SunIcon,
} from "@radix-ui/react-icons";
import {
  Box,
  Button,
  DropdownMenu,
  Flex,
  Grid,
  IconButton,
  Text,
  Tooltip,
} from "@radix-ui/themes";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";

import useWindowSize from "../../../hooks/useWindowSize";
import style from "./Header.module.css";

export default function Header() {
  const { theme, systemTheme, setTheme } = useTheme();
  const resolvedTheme = theme === "system" ? systemTheme : theme;

  const handleThemeToggle = () => {
    const newTheme = resolvedTheme === "dark" ? "light" : "dark";
    const newThemeMatchesSystem = newTheme === systemTheme;
    setTheme(newThemeMatchesSystem ? "system" : newTheme);
  };

  const ThemeToggle = () => {
    return (
      <Tooltip content="Toggle Theme">
        <IconButton
          size="3"
          variant="ghost"
          color="gray"
          onClick={handleThemeToggle}
          aria-label="Toggle Theme"
        >
          {resolvedTheme === "dark" ? <MoonIcon /> : <SunIcon />}
        </IconButton>
      </Tooltip>
    );
  };

  const { width } = useWindowSize();

  if (width !== undefined && width < 768) {
    return (
      <header>
        <nav>
          <Grid columns="2" style={{ gridTemplateColumns: "4fr 10fr" }}>
            <Flex direction="row" align="center" justify="start">
              <NextLink href="/" passHref legacyBehavior>
                <NextImage
                  alt="FlyingSTART Logo"
                  src="/flying-start-logo-v2.png"
                  height={36}
                  width={148}
                  priority
                />
              </NextLink>
            </Flex>

            <Flex direction="row" align="center" justify="end">
              <DropdownMenu.Root>
                <DropdownMenu.Trigger>
                  <IconButton
                    aria-label="Main menu"
                    variant="ghost"
                    color="gray"
                    highContrast
                  >
                    <HamburgerMenuIcon width={"24px"} height={"24px"} />
                  </IconButton>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content
                  variant="soft"
                  style={{ width: "calc(100vw - 22px)" }}
                >
                  <NextLink
                    href="/"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      height: "48px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <DropdownMenu.Item style={{ width: "100%" }}>
                      <Text size="3">Home</Text>
                    </DropdownMenu.Item>
                  </NextLink>
                  <NextLink
                    href="/about-us"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      height: "48px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <DropdownMenu.Item style={{ width: "100%" }}>
                      <Text size="3">About us</Text>
                    </DropdownMenu.Item>
                  </NextLink>
                  <NextLink
                    href="/careers"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      height: "48px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <DropdownMenu.Item style={{ width: "100%" }}>
                      <Text size="3">Careers</Text>
                    </DropdownMenu.Item>
                  </NextLink>
                  <NextLink
                    href="/scholarships"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      height: "48px",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <DropdownMenu.Item style={{ width: "100%" }}>
                      <Text size="3">Scholarships</Text>
                    </DropdownMenu.Item>
                  </NextLink>
                  <Button
                    color="gray"
                    variant="soft"
                    onClick={handleThemeToggle}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DropdownMenu.Item
                      style={{ width: "100%", justifyContent: "center" }}
                    >
                      <ThemeToggle />
                    </DropdownMenu.Item>
                  </Button>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </Flex>
          </Grid>
        </nav>
      </header>
    );
  }

  return (
    <header>
      <nav>
        <Grid columns="3" style={{ gridTemplateColumns: "2fr 10fr 2fr" }}>
          <Flex direction="row" align="center" justify="start">
            <NextLink href="/" passHref legacyBehavior>
              <NextImage
                alt="FlyingSTART Logo"
                src="/flying-start-logo-v2.png"
                height={36}
                width={148}
                priority
              />
            </NextLink>
          </Flex>
          <Flex direction="row" align="center" justify="between">
            <NavigationMenu.Root className={style.NavigationMenuRoot}>
              <NavigationMenu.List className={style.NavigationMenuList}>
                {/* HOME */}
                <NavigationMenu.Item>
                  <Button
                    asChild
                    size="2"
                    variant="soft"
                    className={style.NavigationButton}
                  >
                    <NextLink href="/" color="gray">
                      <Text>Home</Text>
                    </NextLink>
                  </Button>
                </NavigationMenu.Item>
                {/* ABOUT US */}
                <NavigationMenu.Item>
                  <Button
                    asChild
                    size="2"
                    variant="soft"
                    className={style.NavigationButton}
                  >
                    <NextLink href="/about-us" color="gray">
                      <Text>About us</Text>
                    </NextLink>
                  </Button>
                </NavigationMenu.Item>
                {/* CAREERS */}
                <NavigationMenu.Item>
                  <NavigationMenu.Trigger asChild>
                    <Button
                      size="2"
                      variant="soft"
                      className={`${style.NavigationButton} ${style.NavigationMenuTrigger}`}
                    >
                      <Text>Careers</Text>
                      <CaretDownIcon aria-hidden className={style.CaretDown} />
                    </Button>
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content
                    className={style.NavigationMenuContent}
                  >
                    <ul className={`${style.List} ${style.two}`}>
                      <ListItem
                        title="Airline Pilot"
                        href="/careers/commercial/airline-pilot"
                      >
                        Fly as a Private or Recreational pilot, explore the
                        PPL(A), PPL(H) and NPPL.
                      </ListItem>
                      <ListItem
                        title="Helicopter Pilot"
                        href="/careers/commercial/helicopter-pilot"
                      >
                        Master the art of rotary-wing flight with a career in
                        helicopter aviation.
                      </ListItem>
                      <ListItem title="Gliding" href="/careers/gliding">
                        Experience the purest form of flight by becoming a
                        glider pilot.
                      </ListItem>
                      <ListItem
                        title="Private Pilot"
                        href="/careers/private-pilot"
                      >
                        Lorem ipsium dolor sit amet, consectetur adipiscing
                        elit.
                      </ListItem>
                      <ListItem title="Military Pilot" href="/careers/military">
                        Explore careers in the Royal Air Force, Royal Air Corp
                        and Royal Navy
                      </ListItem>
                      <ListItem title="Drones" href="/careers/drones">
                        Lorem ipsium dolor sit amet, consectetur adipiscing
                        elit.
                      </ListItem>
                    </ul>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>
                {/* SCHOLARSHIPS */}
                <NavigationMenu.Item>
                  <Button
                    asChild
                    size="2"
                    variant="soft"
                    className={style.NavigationButton}
                  >
                    <NextLink href="/scholarships" color="gray">
                      <Text>Scholarships</Text>
                    </NextLink>
                  </Button>
                </NavigationMenu.Item>
                {/* INDICATOR */}
                <NavigationMenu.Indicator
                  className={style.NavigationMenuIndicator}
                >
                  <Box className={style.Arrow} />
                </NavigationMenu.Indicator>
                {/* VIEWPORT */}
                <Box asChild className={style.ViewportPosition}>
                  <li>
                    <NavigationMenu.Viewport
                      className={style.NavigationMenuViewport}
                    />
                  </li>
                </Box>
              </NavigationMenu.List>
            </NavigationMenu.Root>
          </Flex>
          <Flex direction="row" align="center" justify="end">
            <Flex gapX="4">
              <ThemeToggle />
            </Flex>
          </Flex>
        </Grid>
      </nav>
    </header>
  );
}

interface ListItemProps {
  children: React.ReactNode;
  title: string;
  href: string;
}

const ListItem: React.FC<ListItemProps> = ({ title, children, href }) => {
  return (
    <li>
      <NavigationMenu.Link asChild>
        <NextLink className={style.ListItemLink} href={href} color="gray">
          <Text as="div" className={style.ListItemHeading}>
            {title}
          </Text>
          <Text className={style.ListItemText}>{children}</Text>
        </NextLink>
      </NavigationMenu.Link>
    </li>
  );
};
