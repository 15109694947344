"use client";

import {
  EnvelopeClosedIcon,
  InstagramLogoIcon,
  TwitterLogoIcon,
} from "@radix-ui/react-icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  Link,
  Separator,
  Text,
  TextField,
} from "@radix-ui/themes";
import NextLink from "next/link";
import { useState } from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import styles from "./Footer.module.css";

export default function Footer() {
  const { width } = useWindowSize();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubscribe = async () => {
    try {
      const res = await fetch("/api/subscribe", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
      const data = await res.json();
      if (data.error) {
        setError(data.error);
      } else {
        setSuccess("You're now subscribed!");
        setEmail("");
      }
    } catch (error: any) {
      setError("Error: " + error.message);
    }
  };

  const renderSignup = () => (
    <Box>
      <Flex direction="column" mb="8">
        {success ? (
          <Text size="6">You're subscribed to email updates</Text>
        ) : (
          <>
            <Text size="6">Sign up for email updates</Text>
            <Text size="3" mt="1" color="gray">
              Keep up with the latest events and opportunities
            </Text>
            <Box maxWidth="250px">
              <TextField.Root
                placeholder="Enter your email address..."
                variant="soft"
                color="gray"
                radius="large"
                mt="4"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Grid columns="1">
                {error && (
                  <Text size="2" mt="2" color="red">
                    {error}
                  </Text>
                )}
              </Grid>
              <Button mt="4" variant="outline" onClick={handleSubscribe}>
                Subscribe
              </Button>
            </Box>
          </>
        )}
      </Flex>
    </Box>
  );

  if (width !== undefined && width < 768) {
    return (
      <Box>
        <Grid asChild pt="3" gapX="9" columns="1" className={styles.Footer}>
          <footer>
            {/* SIGNUP */}
            {renderSignup()}
            <Grid columns="2">
              {/* LEARN MORE */}
              <Box>
                <Heading as="h2" size="2">
                  Learn more
                </Heading>
                <ul>
                  <li>
                    <Text as="p" size="2" mt="3">
                      <NextLink href="/about-us" passHref legacyBehavior>
                        <Link color="gray">About us</Link>
                      </NextLink>
                    </Text>
                  </li>
                  <li>
                    <Text as="p" size="2" mt="3">
                      <NextLink href="/scholarships" passHref legacyBehavior>
                        <Link color="gray">Scholarships</Link>
                      </NextLink>
                    </Text>
                  </li>
                </ul>
              </Box>
              {/* CAREERS */}
              <Box>
                <Heading as="h2" size="2">
                  Careers
                </Heading>
                <ul>
                  <li>
                    <Text as="p" size="2" mt="3">
                      <NextLink href="/careers/gliding" passHref legacyBehavior>
                        <Link color="gray">Gliding</Link>
                      </NextLink>
                    </Text>
                  </li>
                  <li>
                    <Text as="p" size="2" mt="3">
                      <NextLink
                        href="/careers/private-pilot"
                        passHref
                        legacyBehavior
                      >
                        <Link color="gray">Private Pilot</Link>
                      </NextLink>
                    </Text>
                  </li>
                  <li>
                    <Text as="p" size="2" mt="3">
                      <NextLink
                        href="/careers/commercial/airline-pilot"
                        passHref
                        legacyBehavior
                      >
                        <Link color="gray">Airline Pilot</Link>
                      </NextLink>
                    </Text>
                  </li>
                  <li>
                    <Text as="p" size="2" mt="3">
                      <NextLink
                        href="/careers/commercial/helicopter-pilot"
                        passHref
                        legacyBehavior
                      >
                        <Link color="gray">Helicopter Pilot</Link>
                      </NextLink>
                    </Text>
                  </li>
                  <li>
                    <Text as="p" size="2" mt="3">
                      <NextLink
                        href="/careers/military"
                        passHref
                        legacyBehavior
                      >
                        <Link color="gray">Military</Link>
                      </NextLink>
                    </Text>
                  </li>
                  <li>
                    <Text as="p" size="2" mt="3">
                      <NextLink href="/careers/drones" passHref legacyBehavior>
                        <Link color="gray">Drones</Link>
                      </NextLink>
                    </Text>
                  </li>
                </ul>
              </Box>
            </Grid>
            <Separator mt="8" size={"4"}></Separator>
            {/* SOCIALS */}
            <Flex gap="2" align="center" justify="center" mt="5" mb="3">
              <Link
                asChild
                href="https://www.instagram.com/youngairpilots/?hl=en-gb"
                target="_blank"
                color="gray"
              >
                <IconButton
                  style={{ cursor: "pointer" }}
                  size="2"
                  variant="soft"
                  aria-label="Instagram"
                >
                  <InstagramLogoIcon />
                </IconButton>
              </Link>
              <Link
                asChild
                href="https://x.com/airpilotsco"
                target="_blank"
                color="gray"
              >
                <IconButton
                  style={{ cursor: "pointer" }}
                  size="2"
                  variant="soft"
                  aria-label="Twitter"
                >
                  <TwitterLogoIcon />
                </IconButton>
              </Link>
              <Link
                href="mailto:youngmembers@airpilots.org"
                target="_blank"
                color="gray"
              >
                <IconButton
                  style={{ cursor: "pointer" }}
                  size="2"
                  variant="soft"
                  aria-label="Email"
                >
                  <EnvelopeClosedIcon />
                </IconButton>
              </Link>
            </Flex>
            {/* T&C's */}
            <NextLink href="/privacy-policy" passHref legacyBehavior>
              <Text as="p" size="1" align="center" mt="2" color="gray">
                Privacy Policy
              </Text>
            </NextLink>
            <Text as="p" size="1" align="center" mt="2" mb="3" color="gray">
              &copy; 2025 FlyingSTART, All rights reserved.
            </Text>
            <Separator mt="3" size={"4"}></Separator>
            {/* BUILT BY */}
            <Flex direction="column" mt="4">
              <Box>
                <Heading
                  as="h3"
                  size="2"
                  color="gray"
                  highContrast
                  style={{
                    lineHeight: "20px",
                    fontWeight: "inherit",
                  }}
                >
                  Built by{" "}
                  <Link
                    color="gray"
                    highContrast
                    href="https://registe.me"
                    target="_blank"
                    weight="bold"
                  >
                    Ryan
                  </Link>
                </Heading>
              </Box>
            </Flex>
          </footer>
        </Grid>
      </Box>
    );
  }

  return (
    <Box>
      <Grid
        asChild
        pt="3"
        gapX="7"
        columns="3"
        className={styles.Footer}
        style={{ gridTemplateColumns: "8fr 2fr 2fr" }}
      >
        <footer>
          {/* SIGNUP */}
          <Box>
            <Flex direction="column">
              {success ? (
                <Text size="6">You're subscribed to email updates</Text>
              ) : (
                <>
                  <Text size="7">Sign up for email updates</Text>
                  <Text size="4" mt="1" color="gray">
                    Keep up with the latest events and opportunities
                  </Text>
                  <Box maxWidth="250px">
                    <TextField.Root
                      placeholder="Enter your email address..."
                      variant="soft"
                      color="gray"
                      radius="large"
                      mt="4"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Grid columns="1">
                      {success && (
                        <Text size="2" mt="2" color="green">
                          {success}
                        </Text>
                      )}
                      {error && (
                        <Text size="2" mt="2" color="red">
                          {error}
                        </Text>
                      )}
                    </Grid>
                    <Button mt="4" variant="outline" onClick={handleSubscribe}>
                      Subscribe
                    </Button>
                  </Box>
                </>
              )}
            </Flex>
            <Box mb="9"></Box>
          </Box>
          {/* LEARN MORE */}
          <Box>
            <Heading as="h3" size="2">
              Learn more
            </Heading>
            <ul>
              <li>
                <Text as="p" size="2" mt="3">
                  <NextLink href="/about-us" passHref legacyBehavior>
                    <Link color="gray">About us</Link>
                  </NextLink>
                </Text>
              </li>
              <li>
                <Text as="p" size="2" mt="3">
                  <NextLink href="/scholarships" passHref legacyBehavior>
                    <Link color="gray">Scholarships</Link>
                  </NextLink>
                </Text>
              </li>
            </ul>
          </Box>
          {/* CAREERS */}
          <Box>
            <Heading as="h3" size="2">
              Careers
            </Heading>
            <ul>
              <li>
                <Text as="p" size="2" mt="3">
                  <NextLink href="/careers/gliding" passHref legacyBehavior>
                    <Link color="gray">Gliding</Link>
                  </NextLink>
                </Text>
              </li>
              <li>
                <Text as="p" size="2" mt="3">
                  <NextLink
                    href="/careers/private-pilot"
                    passHref
                    legacyBehavior
                  >
                    <Link color="gray">Private Pilot</Link>
                  </NextLink>
                </Text>
              </li>
              <li>
                <Text as="p" size="2" mt="3">
                  <NextLink
                    href="/careers/commercial/airline-pilot"
                    passHref
                    legacyBehavior
                  >
                    <Link color="gray">Airline Pilot</Link>
                  </NextLink>
                </Text>
              </li>
              <li>
                <Text as="p" size="2" mt="3">
                  <NextLink
                    href="/careers/commercial/helicopter-pilot"
                    passHref
                    legacyBehavior
                  >
                    <Link color="gray">Helicopter Pilot</Link>
                  </NextLink>
                </Text>
              </li>

              <li>
                <Text as="p" size="2" mt="3">
                  <NextLink href="/careers/military" passHref legacyBehavior>
                    <Link color="gray">Military</Link>
                  </NextLink>
                </Text>
              </li>
              <li>
                <Text as="p" size="2" mt="3">
                  <NextLink href="/careers/drones" passHref legacyBehavior>
                    <Link color="gray">Drones</Link>
                  </NextLink>
                </Text>
              </li>
            </ul>
          </Box>
          {/* BUILT BY */}
          <Flex align="start" direction="column">
            <Heading
              as="h2"
              size="2"
              color="gray"
              highContrast
              style={{
                lineHeight: "20px",
                fontWeight: "inherit",
              }}
            >
              Built by{" "}
              <Link
                color="gray"
                highContrast
                href="https://registe.me"
                target="_blank"
                weight="bold"
              >
                Ryan
              </Link>
            </Heading>
          </Flex>
        </footer>
      </Grid>
      <Separator mt="6" size={"4"}></Separator>
      <Flex justify="between" mt="5">
        {/* T&C's */}
        <Flex gap="3" align="center">
          <NextLink href="/privacy-policy" passHref legacyBehavior>
            <Text as="p" size="1" style={{ cursor: "pointer" }} color="gray">
              Privacy Policy
            </Text>
          </NextLink>
          <Separator orientation="vertical" />
          <Text as="p" size="1" color="gray">
            &copy; 2025 FlyingSTART, All rights reserved.
          </Text>
        </Flex>
        {/* SOCIALS */}
        <Flex gap="3" align="center">
          <Link
            href="https://www.instagram.com/youngairpilots/?hl=en-gb"
            target="_blank"
            color="gray"
          >
            <IconButton
              style={{ cursor: "pointer" }}
              size="2"
              variant="soft"
              aria-label="Instagram"
            >
              <InstagramLogoIcon />
            </IconButton>
          </Link>
          <Link href="https://x.com/airpilotsco" target="_blank" color="gray">
            <IconButton
              style={{ cursor: "pointer" }}
              size="2"
              variant="soft"
              aria-label="Twitter"
            >
              <TwitterLogoIcon />
            </IconButton>
          </Link>
          <Link
            href="mailto:youngmembers@airpilots.org"
            target="_blank"
            color="gray"
          >
            <IconButton
              style={{ cursor: "pointer" }}
              size="2"
              variant="soft"
              aria-label="Email"
            >
              <EnvelopeClosedIcon />
            </IconButton>
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
}
